import Button from "../Button";
import { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import BanderaInglesa from "./imgHeader/bandera-inglesa.webp";
import BanderaEspañola from "./imgHeader/bandera-españa.webp";
export default function NavBar({ scroll, handleLanguageChange, t }) {
  const [clicked, setClicked] = useState(false);
  const [idioma, setIdioma] = useState();
  // cambia a verdadero o falso a dar un click a un elemento de la navbar
  const handleClick = () => {
    setClicked(!clicked);
  };
  const handleLenguague = () => {
    const lenguaje = localStorage.getItem("Language");
    setIdioma(lenguaje);
  };
  useEffect(() => {
    handleLenguague();
  }, []);
  return (
    <div className={`navBar ${scroll <= -145 ? "scroll" : ""}`}>
      <ScrollLink className="logo" to="home" smooth={true} duration={300}>
        <p onClick={() => setClicked(false)}>KG</p>
      </ScrollLink>
      <Button
        clase="menu"
        clicked={clicked}
        handleClick={handleClick}
        value={<i className="fa-sharp fa-solid fa-bars"></i>}
      />
      <ul className={`list ${clicked ? "active" : ""}`} onClick={handleClick}>
        <ScrollLink
          className="list-element-navbar"
          to="home"
          smooth={true}
          duration={300}
        >
          <p onClick={handleClick}>{t("HOME")}</p>
        </ScrollLink>
        <ScrollLink
          className="list-element-navbar"
          to="about-me"
          offset={-75}
          smooth={true}
          duration={200}
        >
          <p onClick={handleClick}>{t("ABOUTME")}</p>
        </ScrollLink>
        <ScrollLink
          className="list-element-navbar"
          to="experiencia"
          offset={-75}
          smooth={true}
          duration={300}
        >
          <p onClick={handleClick}>{t("EXPERIENCIA")}</p>
        </ScrollLink>
        <ScrollLink
          className="list-element-navbar"
          to="projects"
          offset={-75}
          smooth={true}
          duration={300}
        >
          <p onClick={handleClick}>{t("PROJECTS")}</p>
        </ScrollLink>
        <ScrollLink
          className="list-element-navbar"
          to="contact"
          offset={-75}
          smooth={true}
          duration={300}
        >
          <p onClick={handleClick}>{t("CONTACT")}</p>
        </ScrollLink>
        <div className="cambiador-idioma" onChange={handleLanguageChange}>
          {idioma === "en" ? (
            <div className="opcion" onClick={() => handleLanguageChange("es")}>
              <img src={BanderaEspañola} alt="Española Cambio de Idioma" />
            </div>
          ) : (
            <div className="opcion" onClick={() => handleLanguageChange("en")}>
              <img src={BanderaInglesa} alt="Inglés Cambio de Idioma" />
            </div>
          )}
        </div>
      </ul>
    </div>
  );
}
