import "./myprojects.css";
import ProjectItem from "./Proyectos/ProjectItem";
import BanderaInglesa from "./Home/Header/imgHeader/bandera-inglesa.webp";
import BanderaEspañola from "./Home/Header/imgHeader/bandera-españa.webp";
import { Link } from "react-router-dom";
import projectData from "./projectsData";
import { useEffect, useState } from "react";
const MyProjects = ({ t, changeLanguage }) => {
  const [idioma, setIdioma] = useState();

  const handleLenguague = () => {
    const lenguaje = localStorage.getItem("Language");
    setIdioma(lenguaje);
  };
  useEffect(() => {
    handleLenguague();
  }, []);
  const handleLanguageChange = (e) => {
    changeLanguage(e);
    window.location.href = "/projects";
  };
  return (
    <div className="container-projects full">
      <div className="projects" id="proyectos">
        <Link to="/" className="btn volver-projects">
          {t("Volver")}
        </Link>
        <div
          className="cambiador-idioma myprojects"
          onChange={handleLanguageChange}
        >
          {idioma === "en" ? (
            <div
              className="opcion myprojects"
              onClick={() => handleLanguageChange("es")}
            >
              <img src={BanderaEspañola} alt="Española Cambio de Idioma" />
            </div>
          ) : (
            <div
              className="opcion myprojects"
              onClick={() => handleLanguageChange("en")}
            >
              <img src={BanderaInglesa} alt="Inglés Cambio de Idioma" />
            </div>
          )}
        </div>
        <h1 className="projects-titulo">{t("PROYECTOS")}</h1>
        <div className="projects-content ">
          <h1 className="subtitulo-projects">{t("PROJECTS_SUBTITLE")}</h1>
          <div className="list-projects aparecer">
            {projectData.map((project, index) => {
              return (
                <ProjectItem
                  key={index}
                  id={project.id}
                  imagen={project.imagen}
                  titulo={t(project.titulo)}
                  tecnologias={project.tecnologias}
                  text={t(project.text)}
                  alt={t(project.titulo)}
                  linkWebside={project.linkWebside}
                  linkRepository={project.linkRepository}
                  t={t}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProjects;
