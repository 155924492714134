import CalculadoraJs from "./Proyectos/Projects-img/Calculadora-JS.webp";
import CuentaRegresiva from "./Proyectos/Projects-img/CuentaRegresiva.webp";
import PiedraPapelOTijeras from "./Proyectos/Projects-img/PiedraPapelOTijeras.webp";
import SimonGame from "./Proyectos/Projects-img/Simon-game.webp";
import TaTeTiGame from "./Proyectos/Projects-img/Ta-Te-Ti-Game.webp";
import EcommercePlantasCarnivoras from "./Proyectos/Projects-img/Ecommerce-PlantasCarnivoras.webp";
import paqueteTuristicoLandingPage from "./Proyectos/Projects-img/paqueteTuristico-LandingPage.webp";
import englishSmart from "./Proyectos/Projects-img/english-smart.webp";
import aquarnemc from "./Proyectos/Projects-img/aquarne-mc.webp";
import Monetrix from "./Proyectos/Projects-img/Monetrix.webp";
import CoderPlate40 from "./Proyectos/Projects-img/CoderPlate40.webp";
import JikesShop from "./Proyectos/Projects-img/JikesShop.webp";
import LazonaRoleplay from "./Proyectos/Projects-img/LazonaRoleplay.png";
import webEcommerce from "./Proyectos/Projects-img/web-ecommerce.png";
import CoderPlate40Agencia from "./Proyectos/Projects-img/CoderPlate40-agencia.webp";

const projectData = [
  {
    id: 4,
    imagen: CoderPlate40Agencia,
    titulo: "CoderPlate40Titulo",
    tecnologias: "ReactJs, ExpressJs and MongoDB.",
    text: "textCoderplate40",
    linkWebside: "https://coderplate40.online",
    linkRepository: "/",
  },
  {
    id: 4,
    imagen: CoderPlate40,
    titulo: "CoderPlate40TituloViejo",
    tecnologias: "ReactJs, ExpressJs and MongoDB.",
    text: "textCoderplate40Viejo",
    linkWebside: "https://coderplate40.com",
    linkRepository: "/",
  },
  {
    id: 11,
    imagen: webEcommerce,
    titulo: "coderplate40 - Demo Tienda Online",
    tecnologias: "ReactJs, ExpressJs and MongoDB.",
    text: "Tienda Online - Ecommerce",
    linkWebside: "https://demo.tienda.coderplate40.online",
    linkRepository: "/",
  },
  {
    id: 2,
    imagen: JikesShop,
    titulo: "JikesShopTitulo",
    tecnologias: "ReactJs, Express and MongoDB.",
    text: "textJikesshop",
    linkWebside: "https://jikesshop.coderplate40.com",
    linkRepository: "/",
  },
  {
    id: 1,
    imagen: LazonaRoleplay,
    titulo: "Lazona Roleplay",
    tecnologias: "ReactJs and Mysql.",
    text: "textLazonaRoleplay",
    linkWebside: "https://lazona-roleplay.coderplate40.com",
    linkRepository: "/",
  },
  {
    id: 3,
    imagen: Monetrix,
    titulo: "MonetrixTitulo",
    tecnologias: "ReactJs, Bootstrap, Express and Mysql.",
    text: "textMonetrix",
    linkWebside: "https://monetrix.coderplate40.com",
    linkRepository: "/",
  },

  {
    id: 5,
    imagen: englishSmart,
    titulo: "englishsmartTitulo",
    tecnologias: "REACT, Bootstrap, Express and Mysql.",
    text: "textenglishSmart",
    linkWebside: "https://englishsmart.coderplate40.com",
    linkRepository: "/",
  },
  {
    id: 6,
    imagen: paqueteTuristicoLandingPage,
    titulo: "paqueteTuristicoLandingPageTitulo",
    tecnologias: "Material UI, REACT.",
    text: "textpaqueteTuristicoLandingPage",
    linkWebside: "https://pagina-aerolineas.netlify.app/",
    linkRepository: "/",
    // linkRepository:
    //   "https://github.com/kevingonzalo/pagina-aerolineas-LandingPage",
  },
];
export default projectData;
