import { useState } from "react";

const Footer = ({ t }) => {
  let añoActual = new Date().getFullYear();

  return (
    <footer className="footer">
      <p>
        Copyright © {añoActual}.
        <br className="footer-active" />
        <a href="https://www.linkedin.com/in/kevin-balmaceda-a0350b199/">@KevinBalmaceda</a>
        {t("Copyright")}
      </p>
    </footer>
  );
};
export default Footer;
