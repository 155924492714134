const Spinner = ({ t }) => {
  return (
    <div className="spinner-container">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h3>{t("Loading")}</h3>
    </div>
  );
};

export default Spinner;
