import "./App.css";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18n from "./i18next";
import { useState } from "react";
import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./components/Home";
import MyProjects from "./components/MyProjects";
import Spinner from "./components/Spinner";
import Footer from "./components/Home/Footer";
import WebsiteProject from "./components/WebsiteProject";
import Share from "./components/share/Share";
const URL = process.env.REACT_APP_URL;

export default function App() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [scroll, setScroll] = useState(0);
  const ref = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const div = ref.current;
      const { y } = div.getBoundingClientRect();
      const scroll = y;
      setScroll(scroll);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    // Hacer scroll hacia arriba cuando se hace clic en el botón
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("Language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      i18n.changeLanguage("es");
      localStorage.setItem("Language", "es");
    }
  }, []);

  const changeLanguage = (selectedLanguage) => {
    if (selectedLanguage !== "es" && selectedLanguage !== "en") {
      return;
    }
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("Language", selectedLanguage); // Guardar el idioma seleccionado en el almacenamiento local
  };
  const handleLanguageChange = (e) => {
    window.location.href = "/";
    changeLanguage(e);
  };
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="contenedor">
      {loading ? (
        <Spinner t={t} />
      ) : (
        <>
          <div className={`btn-subir ${scroll <= -259.71875 ? "scroll" : ""}`}>
            <button onClick={handleScrollToTop}>
              <i className="fa-solid fa-arrow-up"></i>
            </button>
          </div>

          <div
            style={{
              zIndex: "9999",
              position: "fixed",
              bottom: "5%",
              right: "5%",
            }}
          >
            <Share isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>
          <div ref={ref} onClick={() => setIsOpen(false)}>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Home
                      handleLanguageChange={handleLanguageChange}
                      t={t}
                      URL={URL}
                    />
                  }
                />
                <Route
                  path="/projects"
                  element={<MyProjects t={t} changeLanguage={changeLanguage} />}
                />
                <Route
                  path="/projects/:id"
                  element={
                    <WebsiteProject t={t} changeLanguage={changeLanguage} />
                  }
                />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </BrowserRouter>
          </div>
          <Footer t={t} />
        </>
      )}
    </div>
  );
}
