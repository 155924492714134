import "../../Estilos/Variables.css";
import "../../Estilos/Header.css";
import imgHeader from "./imgHeader/img-header.webp";
import CV_Es from "./imgHeader/KevinBalmaceda CV-es.pdf";
import CV_En from "./imgHeader/KevinBalmaceda CV-en.pdf";
import { useEffect, useState } from "react";
export default function Header({ handleLanguageChange, t }) {
  const [idioma, setIdioma] = useState();
  const handleLenguague = () => {
    const lenguaje = localStorage.getItem("Language");
    setIdioma(lenguaje);
  };
  useEffect(() => {
    handleLenguague();
  }, []);
  return (
    <header className="header" id="home">
      <div className="header-content">
        <div className="background"></div>
        <div className="description">
          <img src={imgHeader} alt="img header Portfolio desarrollo web " className="img-header" />
          <div className="description-text">
            <h1 className="Description-Titulo-uno">Kevin Balmaceda</h1>
            <h1 className="Description-Titulo-dos">Fullstack Developer</h1>
            <div className="button-container" title="Download CV">
              {idioma === "es" ? (
                <a href={CV_Es} download="KevinBalmaceda CV-es.pdf" className="btn">
                  {t("DownloadCV")}
                </a>
              ) : (
                <a href={CV_En} download="KevinBalmaceda CV-en.pdf" className="btn">
                  {t("DownloadCV")}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
