import React from "react";
import { Link } from "react-router-dom";
import "../Estilos/experiencia.css";
export default function Experiencie({ t }) {
  return (
    <section className="experiencia" id="experiencia">
      <h1 className="projects-titulo">{t("EXPERIENCIA")}</h1>
      <main>
        <div className="projects-content">
          {/* <h1 className="subtitulo-projects">{t("Experience_SUBTITLE")}</h1> */}
          <div className="list-experiencia">
            <div className="timeline">
              <div className="outer">
                <div className="card">
                  <div className="info">
                    <h3 className="title">2020 - 2021 </h3>
                    <h4>Autónomo / Freelancer</h4>
                    <h4>1 Año - Desarrollador web Frontend</h4>
                    <p>{t("text1_experiencia")}</p>
                  </div>
                </div>
                <div className="card">
                  <div className="info">
                    <h3 className="title">2021 - 2022</h3>
                    <h4>Autónomo / Freelancer / Pymes</h4>
                    <h4>9 meses - Desarrollador web Fullstack</h4>
                    <p>{t("text2_experiencia")}</p>
                  </div>
                </div>
                <div className="card">
                  <div className="info">
                    <h3 className="title">2022 - Actualidad</h3>
                    <h4>
                      coderplate40 - Empresa de Servicios Digitales{" "}
                      <a
                        href="https://coderplate40.online"
                        target="_blank"
                        style={{
                          zIndex: "1000 !important",
                          color: "white",
                          cursor: "pointer",
                        }}
                      >
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                      </a>
                    </h4>
                    <h4>
                      4 Años - Desarrollo Fullstack y Despliegue de Sitíos web
                    </h4>
                    <p>{t("text3_experiencia")}</p>
                  </div>
                </div>
                {/* <div className="card">
                  <div className="info">
                    <h3 className="title">Title 4</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.{" "}
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="info">
                    <h3 className="title">Title 5</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.{" "}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}
