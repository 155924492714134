import { Link } from "react-router-dom";
export default function ProjectItem(props) {
  return (
    <div className="projects__item">
      <div className="project-img">
        <img src={props.imagen} alt={props.alt} />
      </div>
      <div className="projects-item-description">
        <h1>{props.titulo}</h1>
        <h3>{props.tecnologias}</h3>
        {/* <p>
          <strong>Descripción:</strong> {props.text}
        </p> */}
        <div className="project-bottom-container">
          <a
            href={props.linkWebside}
            className="webside"
            target="_blank"
            rel="noopenr noreferrer"
          >
            <i className="fa-solid fa-earth-americas"></i> {props.t("Website")}
          </a>
          {props.linkRepository !== "/" && (
            <>
              <a
                href={props.linkRepository}
                className="repository"
                target="_blank"
                rel="noopenr noreferrer"
              >
                <i className="fa-brands fa-github icon-repository"></i>{" "}
                {props.t("Repository")}
              </a>
              <br className="footer-active" />
              <br className="footer-active" />
            </>
          )}
          {/* <a href={`/projects/${props.id}`}>
            <i className="fa-solid fa-circle-info" aria-hidden="true"></i> Mas
            Info
          </a> */}
        </div>
      </div>
    </div>
  );
}
