import React from "react";
import Header from "./Home/Header/Header";
import AboutMe from "./Home/AboutMe";
import Projects from "./Proyectos/Projects";
import Contact from "./Home/Contact";
import NavBar from "./Home/Header/NavBar";
import { useState, useRef } from "react";
import { useEffect } from "react";
import ScrollToTop from "../ScrollToTop";
import Experiencie from "./Home/Experiencie";

export default function Home({ handleLanguageChange, URL, t }) {
  const [scroll, setScroll] = useState(0);
  const ref = useRef();

  // cambia el valor del scroll true o false para activar el efecto del scroll
  useEffect(() => {
    const handleScroll = () => {
      const div = ref.current;
      const { y } = div.getBoundingClientRect();
      const scroll = y;
      setScroll(scroll);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("isVisible");
        observer.unobserve(entry.target);
      }
    });
  };
  useEffect(() => {
    const mains = document.querySelectorAll("main");
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "-15px",
      threshold: 0.2, // Change this threshold as needed
    });
    mains.forEach((section) => {
      observer.observe(section);
    });
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <ScrollToTop />
      <div ref={ref}>
        <NavBar
          scroll={scroll}
          handleLanguageChange={handleLanguageChange}
          t={t}
        />
        <Header handleLanguageChange={handleLanguageChange} t={t} />
        <AboutMe t={t} />
        <Experiencie t={t} />
        <Projects t={t} />
        <Contact t={t} URL={URL} />
      </div>
    </>
  );
}
