import "./myprojects.css";
import BanderaInglesa from "./Home/Header/imgHeader/bandera-inglesa.webp";
import BanderaEspañola from "./Home/Header/imgHeader/bandera-españa.webp";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import projectsData from "./projectsData";
const WebsiteProject = ({ t, changeLanguage }) => {
  const [idioma, setIdioma] = useState();
  const [website, setWebsite] = useState([]);
  const { id } = useParams();
  const hadleWebsite = () => {
    setWebsite(projectsData.find((project) => project.id == id));
  };
  const handleLenguague = () => {
    const lenguaje = localStorage.getItem("Language");
    setIdioma(lenguaje);
  };
  useEffect(() => {
    handleLenguague();
    hadleWebsite();
  }, []);
  const handleLanguageChange = (e) => {
    changeLanguage(e);
    window.location.href = `/projects/${website.id}`;
  };
  return (
    <div className="container-projects project full">
      <div className="projects" id="proyectos">
        <Link to="/projects" className="btn volver-projects project">
          {t("Volver")}
        </Link>
        <div
          className="cambiador-idioma myprojects"
          onChange={handleLanguageChange}
        >
          {idioma === "en" ? (
            <div
              className="opcion myprojects"
              onClick={() => handleLanguageChange("es")}
            >
              <img src={BanderaEspañola} alt="Española Cambio de Idioma" />
            </div>
          ) : (
            <div
              className="opcion myprojects"
              onClick={() => handleLanguageChange("en")}
            >
              <img src={BanderaInglesa} alt="Inglés Cambio de Idioma" />
            </div>
          )}
        </div>
        <br />
        <div className="projects-content aparecer">
          {/* <h1 className="projects-titulo project">{t(`${website.titulo}`)}</h1> */}
          <div className="list-projects project aparecer">
            <div className="list-project_img">
              <img src={website.imagen} alt={t(website.titulo)} />
            </div>
            <div className="list-project_project">
              <h1>{t(website.titulo)}</h1>
              <p className="descripcion-web">{t(website.text)}</p>
              <div className="project-bottom-container projects">
                <a
                  href={website.linkWebside}
                  className="webside"
                  target="_blank"
                  rel="noopenr noreferrer"
                >
                  <i className="fa-solid fa-earth-americas"></i> {t("Website")}
                </a>
                {website.linkRepository !== "/" && (
                  <>
                    <a
                      href={website.linkRepository}
                      className="repository"
                      target="_blank"
                      rel="noopenr noreferrer"
                    >
                      <i className="fa-brands fa-github icon-repository"></i>{" "}
                      {t("Repository")}
                    </a>
                    <br className="footer-active" />
                    <br className="footer-active" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteProject;
