// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const fechaInicio = new Date("2020-01-01");
const fechaActual = new Date();
const diferenciaMilisegundos = fechaActual - fechaInicio;
const diferenciaAnios = Math.floor(
  diferenciaMilisegundos / (1000 * 60 * 60 * 24 * 365)
);

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        // spinner
        Loading: "Loading...",
        // navbar
        HOME: "HOME",
        ABOUTME: "ABOUT ME",
        PROJECTS: "PROJECTS",
        // Header
        DownloadCV: "Download CV",
        // AboutME
        Studentof: "Student of",
        TrainingandPersonality: "Training and Personality",
        Carrera:
          "Bachelor's in systems from the National University of La Plata",
        description: ` and FullStack Web Developer with ${diferenciaAnios} years of experience in Development, Maintenance and Deployment of Web Applications with a wide variety of technologies. I obtained my knowledge at the university about theory and logic in
        the great part and another part self-taught with courses, documentation and videos. I like to constantly learn new things and as a Freelancer I learn new tools every day that the projects my clients request demand.
        I constantly carry out projects perfecting my programming and English knowledge. I like to give and receive feedback so I like and am good at working in a team, although I also perform well alone.
        My salary expectation is always in line with the job position.`,
        SkillsandTechnologies: "Skills and Technologies",
        // Projects
        PROYECTOS: "PROJECTS",
        PROJECTS_SUBTITLE: "Some of the Projects I have worked on.",
        englishsmartTitulo: "EnglishSmart - English Learning",
        Website: "Website",
        Repository: "Repository",
        MonetrixTitulo: "Monetrix - Matched Betting and Sports Betting",
        CoderPlate40Titulo: "coderplate40 - Web Page Development ",
        CoderPlate40TituloViejo:
          "coderplate40 - Web Page Development - old web",
        CalculadoraJsTitulo: "Interactive Calculator",
        btnVerTodosProyectos: "View All Projects",
        EcommercePlantasCarnivorasTitulo: "E-commerce Demo Carnivorous Plants",
        aquarnemcTitulo: "AquarneMC - Server of Minecraft",
        PiedraPapelOTijerasTitulo: "Rock Paper Scissors",
        SimonGameTitulo: "Simon says",
        JikesShopTitulo: "Jikesshop - tools store for Discord",
        // text projects
        textJikesshop:
          "Jikesshop is an online store that offers a service of manageable Discord bots, including a control panel, clusters and all types of management for each plan. This application is created with ReactJS and Express with the DiscordJs tool and MongoDB as a Database, user management is applied, purchase of different plans, responsive design and customized by the client along with optimized performance settings, all routed with React Router DOM and Server Side Rendering.",
        textMonetrix:
          "Use of design tools such as Bootstrap and Material UI and Axios. On the server side, I connect everything with a user authentication, JWT, localStorage, Nodemailer, and bcrypt. Also, I use web scraping with Puppeteer and MySQL. Additionally, I use the PayPal API to manage plans, setting up recurring payments. The deployment was carried out on a VPS server with Linux on IONOS.",
        textCoderplate40:
          "coderplate40 is a company Created by Me, Specialized in the development, maintenance and deployment of web pages. provides complete and effective solutions for the digital needs of our clients. It is a Young Project so I would like to implement more services, such as Development Courses or Template Sales.",
        textCoderplate40Viejo:
          "coderplate40 this is the old website of my company, Specialized in the development, maintenance and deployment of web pages. provides complete and effective solutions for the digital needs of our clients. It is a Young Project so I would like to implement more services, such as Development Courses or Template Sales.",
        textenglishSmart:
          "EnglishSmart is an educational company committed to imparting English language skills through interactive courses based on question and answer logic. In addition, it has a solid backend infrastructure with axios for requests, including payment processing, donation management, user authentication and data storage in a MySQL database.",
        textpaqueteTuristicoLandingPage:
          "I have had the privilege of developing a Landing Page for an exciting tour package using ReactJS and Material UI. This project is not a simple demo, but a real project that was taken to production for a client.",
        textaquarnemc:
          "The AquarneMC Website is a project designed specifically for a Minecraft server, complying with the aesthetics and themes requested by the client.",
        textEcommercePlantasCarnivoras:
          "This project is not only a learning exercise, but also showcases my ability to create an effective and enjoyable online shopping experience. I am excited to continue learning and improving my skills in web development. The heart of a successful e-commerce is your shopping cart, and I have implemented a fully functional one in this project. Users can add and remove products, as well as view the summary of their purchase before completing it.",
        textPiedraPapelOTijeras:
          "Rock Paper or Scissors Portfolio Project with Js, it is a skills test, it is made with JavaScript and contains basic logic, it was one of the first projects I did.",
        textSimonGame:
          "Simon Game Portfolio Project with Js, it is a skills test, it is made with JavaScript and contains logic of medium complexity, it was one of the first projects I did",
        textTaTeTiGame:
          "TaTeTi Game Portfolio Project with ReactJs, it is a test of skills, it is made with ReactJs and the most beautiful design is pleasant for the user, it contains a logic of medium complexity, it was one of the first projects I did with ReactJs",
        textCalculadoraJs:
          "Interactive calculator created using web technologies such as HTML, CSS and JavaScript, which uses Objects and methods to perform each arithmetic calculation. This calculator is not only functional but also has an attractive and user-friendly design.",
        textCuentaRegresiva:
          "Countdown created using web technologies such as HTML, CSS and JavaScript for what is done Date and methods to do each the clock system in reverse.",
        textbiometricauthenticationsystem:
          "Dashboard system with user management of a company. The application is to activate fingerprint recognition to provide a note of presence at work, that is, it is so that workers have notice of their presence.",
        textLazonaRoleplay:
          "Immerse yourself in the thrilling world of GTA roleplay with our ReactJS-powered website! Navigate through a seamless experience crafted with Swiper sliders, showcasing a vast array of in-game items. With MySQL and Express powering our backend through Node.js, your shopping adventure for GTA goodies is just a click away!",
        //  contact
        EXPERIENCIA: "EXPERIENCE",
        CONTACT: "CONTACT",
        namePlaceholder: "NAME",
        messagePlaceholder: "MESSAGE",
        enviarPlaceholder: "SEND",
        Ponealgo: "Please leave a message",
        MasCaracteres: "Please Enter 50 or more Characters",
        // footer
        Copyright: "All rights reserved",
        // mensajes error
        messageError: "¡Too many messages sent!",
        messageSuccess: "¡Message sent successfully!",
        Youclickedthebutton:
          "¡Thank you for your message, I will respond as soon as possible!",
        LotMessage: "¡You have sent too many messages!",
        NotMessage: "¡Error send Message!",
        MasInfo: "More Info",
        Volver: "Back",
        // experiencia
        text1_experiencia:
          "Development of static web templates with technologies such as html, css, Javascript, ReactJs, Nextjs, Bootstrap and MaterialUI which sell to individual clients or small businesses as freelancers.",
        text2_experiencia:
          "Development and maintenance of websites for personal ventures of Clients and companies, with good aesthetics and accessibility, SEO management and implementation of good programming and Development practices.",
        text3_experiencia:
          "Development, Maintenance and Deployment of Fullstack Websites. Creation, Configuration and Management of Databases and servers in the cloud on a Linux system (Ubuntu, CentOS and Debian), configuration of DNS Domains and Subdomains and also SSL with Nginx in a Production and Development environment.",
      },
    },
    es: {
      translation: {
        // spinner
        Loading: "Cargando...",
        // navbar
        HOME: "INICIO",
        ABOUTME: "SOBRE MÍ",
        PROJECTS: "PROYECTOS",
        DownloadCV: "Descargar CV",
        Studentof: "Estudiante de",
        TrainingandPersonality: "Formación y Personalidad",
        Carrera:
          "Licenciatura en sistemas por la Universidad Nacional de La Plata",
        description: `, y Desarrollador Web FullStack con ${diferenciaAnios} años de experiencia en Desarrollo, Mantenimiento y Despliegue de Aplicaciones Web con una Amplia variedad de tecnologías. Obtuve mis conocimientos en la universidad sobre teoría y lógica en
        la gran parte y otra parte de forma autodidacta con cursos, documentación y vídeos. Me gusta aprender constantemente cosas nuevas y como Freelance aprendo todos los días nuevas herramientas que demandan los proyectos que piden mis clientes.
        Constantemente realizo proyectos perfeccionando mis conocimientos de programación e inglés. Me gusta dar y recibir feedback por lo que me gusta y se me da bien trabajar en equipo, aunque también me desempeño bien solo.
        Mi pretención salarial siempre es acorde al puesto de trabajo.`,
        SkillsandTechnologies: "Habilidades y Tecnologías",
        // Projects
        PROYECTOS: "PROYECTOS",
        PROJECTS_SUBTITLE: "Algunos de los Proyectos en los que he trabajado.",
        englishsmartTitulo: "EnglishSmart - Aprendizaje de inglés",
        Website: "Sitío Web",
        Repository: "Repositorio",

        MonetrixTitulo: "Monetrix - Matched Betting y Apuestas Deportivas",
        CoderPlate40Titulo: "coderplate40 - Desarrollo de Páginas Web",
        CoderPlate40TituloViejo:
          "coderplate40 - Desarrollo de Páginas Web - antigua web",
        CalculadoraJsTitulo: "Calculadora Interactiva",
        EcommercePlantasCarnivorasTitulo: "E-commerce Demo Plantas Carnivoras",
        paqueteTuristicoLandingPageTitulo: "Paquete Turistico LandingPage",
        aquarnemcTitulo: "AquarneMC - Servidor de Minecraft",
        PiedraPapelOTijerasTitulo: "Piedra Papel o Tijeras",
        SimonGameTitulo: "Simon Dice",
        JikesShopTitulo: "Jikesshop - tienda de herramientas para discord",
        btnVerTodosProyectos: "Ver Todos Proyectos",
        // text projects
        textJikesshop:
          "Jikesshop es una tienda online que ofrece un servicio de bots gestionables de Discord, incluye panel de control, clústeres y todo tipo de gestiones para cada plan. Esta aplicación está creada con ReactJS y Express con la herramienta DiscordJs y MongoDB como Base de datos, se aplica gestión de usuarios, compra de distintos planos, diseño responsivo y personalizado por el cliente a la par de ajustes de rendimiento optimizado, todo enrutado con React Router DOM y Server Side Rendering.",
        textMonetrix:
          "Uso de herramientas de diseño como Bootstrap y Material UI y Axios. Del lado del servidor, conecto todo con autenticación de usuario, JWT, localStorage, Nodemailer y bcrypt. Además, uso web scraping con Puppeteer y MySQL. Además, utilizo la API de PayPal para gestionar planes y configurar pagos recurrentes. El despliegue se realizó en un servidor VPS con Linux en IONOS",
        textCoderplate40:
          "coderplate40 es una empresa Creada por Mí, Especializada en el desarrollo, mantenimiento y despliegue de páginas web. brinda soluciones completas y efectivas para las necesidades digitales de nuestros clientes. Es un Proyecto Joven por lo que me gustaría implementar más servicios, como Cursos de Desarrollo o Venta de Plantillas.",
        textCoderplate40Viejo:
          "coderplate40 esta es la web antigua de mi empresa, Especializada en el desarrollo, mantenimiento y despliegue de páginas web. brinda soluciones completas y efectivas para las necesidades digitales de nuestros clientes. Es un Proyecto Joven por lo que me gustaría implementar más servicios, como Cursos de Desarrollo o Venta de Plantillas.",
        textenglishSmart:
          "EnglishSmart es una empresa educativa comprometida con impartir habilidades en el idioma inglés a través de cursos interactivos basados ​​en la lógica de preguntas y respuestas. Además, cuenta con una sólida infraestructura backend con axios para solicitudes, incluyendo procesamiento de pagos, gestión de donaciones, autenticación de usuarios y almacenamiento de datos en una base de datos MySQL.",
        textpaqueteTuristicoLandingPage:
          "Tuve el privilegio de desarrollar una página de destino para un paquete turístico emocionante utilizando ReactJS y Material UI. Este proyecto no es una simple demostración, sino un proyecto real que fue llevado a producción para un cliente.",
        textaquarnemc:
          "El Sitio Web AquarneMC es un proyecto diseñado específicamente para un servidor de Minecraft, cumpliendo con la estética y temática solicitada por el cliente.",
        textEcommercePlantasCarnivoras:
          "Este proyecto no es sólo un ejercicio de aprendizaje, sino que también muestra mi capacidad para crear una experiencia de compra online eficaz y agradable. Estoy emocionado de continuar aprendiendo y mejorando mis habilidades en desarrollo web. El corazón de un comercio electrónico exitoso es su carrito de compras, y en este proyecto he implementado uno completamente funcional. Los usuarios pueden agregar y quitar productos, así como ver el resumen de su compra antes de completarla.",
        textPiedraPapelOTijeras:
          "Proyecto de Porfolio Piedra Papel o Tijeras con Js, es una prueba de habilidades, está realizado con JavaScript y contiene una lógica básica, fue uno de los primeros proyectos que hice.",
        textSimonGame:
          "Proyecto de Porfolio Simon Game con Js, es una prueba de habilidades, está realizado con JavaScript y contiene una lógica de complejidad media, fue uno de los primeros proyectos que hice.",
        textTaTeTiGame:
          "Proyecto de Porfolio TaTeTi Game con ReactJs, es una prueba de habilidades, está realizado con ReactJs y diseño más bonito agradable parra el usuario contiene una lógica de complejidad media, fue uno de los primeros proyectos que hice con ReactJs.",
        textCalculadoraJs:
          "Calculadora interactiva creada utilizando tecnologías web como HTML, CSS y JavaScript para lo que se hace uso de Objetos y métodos para hacer cada cálculo aritmético. Esta calculadora no es solo funcional, sino que también tiene un diseño atractivo y amigable para el usuario",
        textCuentaRegresiva:
          "Cuenta Regresiva creada utilizando tecnologías web como HTML, CSS y JavaScript para lo que se hace Date y metodos para hacer cada el sistema reloj en reversa.",
        textbiometricauthenticationsystem:
          "Sistema dashboard con gestion de usuarios de una empresa. la aplicacion es para activar el reconocimiento dactilar para dar apunte de presencialidad al trabajo, es decir es para que los trabajadores tengan aviso de su presencialidad.",
        textLazonaRoleplay:
          "Sumérgete en el emocionante mundo del roleplay de GTA con nuestro sitio web impulsado por ReactJS. Navega a través de una experiencia perfecta diseñada con Swiper sliders, mostrando una amplia variedad de artículos del juego. Con MySQL y Express impulsando nuestro backend a través de Node.js, ¡tu aventura de compras para los tesoros de GTA está a solo un clic de distancia!",
        // contat
        EXPERIENCIA: "EXPERIENCIA",
        CONTACT: "CONTACTO",
        namePlaceholder: "NOMBRE",
        messagePlaceholder: "MENSAJE",
        enviarPlaceholder: "ENVIAR",
        MasCaracteres: "Por favor Introducir 50 o más Caracteres",
        Ponealgo: "Por favor, deje un mensaje",
        // footer
        Copyright: "todos los derechos Reservados",
        // mensajes error
        messageError: "¡Demasiados mensajes enviados!",
        messageSuccess: "¡Mensaje enviado correctamente!",
        Youclickedthebutton: "¡Gracias por tu mensaje, te hablo a la brevedad!",
        LotMessage: "¡Enviaste demasiado Mensajes!",
        NotMessage: "¡Error al enviar Mensaje!",
        MasInfo: "Mas Info",
        Volver: "Volver",
        // experiencia
        text1_experiencia:
          "Desarrollo de Plantillas web estáticas con tecnologías como html, css, Javascript, ReactJs, Nextjs, Bootstrap y MaterialUI las cuales vendia a clientes particulares o empresas pequeñas.",
        text2_experiencia:
          "Desarrollo y mantenimiento de Sitíos web Fullstack para emprendimientos personales de Clientes y empresas, con buena estetica y accesibilidad, manejo del SEO e implementación de buenas practicas de programación y Manejo de GIT y GitHub para repositorios.",
        text3_experiencia:
          "Desarrollo, Mantenimiento y Despliegue de Sitios web Fullstack. Creación, Configuración y Gestión de Bases de datos y servidores en la nube en sistema Linux (Ubuntu, CentOS y Debian), configuración de DNS Dominios y Subdominios y también SSL con Nginx en entorno de Producción y Desarrollo.",
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
