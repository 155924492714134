import BoxContact from "./BoxContact";
import "../Estilos/Contact.css";
import "../Estilos/FormularioContacto.css";
import FormularioContacto from "./FormularioContacto";
const Contact = ({ t, URL }) => {
  return (
    <section className="contact" id="contacto">
      <h1 className="contact-titulo">{t("CONTACT")}</h1>
      <main className="contacto-main">
        {/* <FormularioContacto t={t} URL={URL} /> */}
        {/* <div className="box-contact"> */}
        <BoxContact
          claseMail="mail"
          enlace="mailto:kevinbalmaceda5@gmail.com"
          claseIcon="fa-regular fa-envelope mail-icon"
          parrafo="Mail"
        />
        <BoxContact
          enlace="https://www.linkedin.com/in/kevin-balmaceda-a0350b199/"
          claseIcon="fa-brands fa-linkedin-in linkedin-icon"
          parrafo="Linkedin"
        />
        <BoxContact
          enlace="https://github.com/kevinbalmaceda"
          claseIcon="fa-brands fa-github github-icon"
          parrafo="GitHub"
        />
        {/* </div> */}
      </main>
    </section>
  );
};
export default Contact;
