import "./Share.css";

const Share = ({ isOpen, setIsOpen }) => {
  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  // Filtrar los métodos de contacto disponibles
  const contactMethods = [
    // {
    //   type: "email",
    //   icon: <EmailIcon style={{ color: "white", fontSize: "2rem" }} />,
    //   link: email ? `mailto:${email}` : null,
    // },
    {
      type: "whatsapp",
      icon: (
        <i
          className="fab fa-whatsapp icon-0-0-22"
          style={{ color: "white", fontSize: "2rem" }}
        ></i>
      ),
      link: "https://walink.co/76b19d",
    },
    // {
    //   type: "instagram",
    //   icon: <InstagramIcon style={{ color: "white", fontSize: "2rem" }} />,
    //   link: instagram,
    // },
  ].filter((method) => method.link); // Solo mantener los que tienen un link válido

  const methodCount = contactMethods.length;

  if (methodCount === 1) {
    const { icon, link, type } = contactMethods[0];

    return (
      <a
        className={`single-contact ${type}`} // Se añade la clase específica del tipo de contacto
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {icon}
      </a>
    );
  }

  return (
    <>
      <div className="social-button">
        {/* <button className="toggle-button" onClick={toggleOptions}>
            <i
              className={`fas fa-share-alt icon-0-0-28 ${
                isOpen ? "active" : ""
              }`}
            ></i>
            <i className={`fa-solid fa-x ${isOpen ? "" : "active"}`}></i>
          </button> */}
        <div className={`social-options ${isOpen ? "active" : ""}`}>
          {contactMethods.map((method, index) => (
            <a
              key={index}
              href={method.link}
              target="_blank"
              rel="noopener noreferrer"
              className={method.type}
            >
              {method.icon}
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default Share;
