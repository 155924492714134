import "../Estilos/AboutMe.css";
// logos
import html from "./logos/html.svg";
import css from "./logos/css.svg";
import js from "./logos/javascript.svg";
import react from "./logos/react.svg";
import git from "./logos/git.svg";
import github from "./logos/github.svg";
import nodejs from "./logos/nodejs.svg";
import typescript from "./logos/typescript.svg";
import filezilla from "./logos/filezilla-logo.svg";
import express from "./logos/express.svg";
import nextjs from "./logos/nextjs.svg";
import mysql from "./logos/mysql.svg";
import mongodb from "./logos/mongodb.svg";
import googlecloud from "./logos/google-cloud.svg";
import terminal from "./logos/terminal.svg";
import debian from "./logos/debian.svg";
import java from "./logos/java.svg";
import bootstrap from "./logos/bootstrap.svg";
import materialUi from "./logos/material-ui.svg";
import photoshop from "./logos/photoshop.svg";
// import nextjs from "./logos/next-js.webp";
export default function AboutMe({ value, t }) {
  return (
    <section className="sobreMi" id="about-me">
      <h1 className="About-me-titulo">{t("ABOUTME")}</h1>
      <main>
        <div className="about-me-content">
          <div className="description-me">
            <h1 className="About-me-subtitulo">
              {t("TrainingandPersonality")}
            </h1>
            <p className="parrafo-description">
              {t("Studentof")}{" "}
              <a
                href="https://www.info.unlp.edu.ar/licenciatura-en-sistemas-plan-2021/"
                title="Web de la Carrera"
                target="_blank"
                rel="noreferrer"
              >
                {t("Carrera")}
              </a>
              {t("description")}
            </p>
          </div>
          <div className="my-skills">
            <h1 className="About-me-subtitulo">{t("SkillsandTechnologies")}</h1>
            <div className="logos">
              {/* <img src={html} alt="logo de HTML Portfolio Kevin Balmaceda" />
              <img src={css} alt="logo de CSS Portfolio Kevin Balmaceda" />
              <img
                src={js}
                alt="logo de JAVASCRIPT Portfolio Kevin Balmaceda"
              /> */}
              <img src={react} alt="logo de react Portfolio Kevin Balmaceda" />
              <img
                src={nextjs}
                alt="logo de nextjs Portfolio Kevin Balmaceda"
              />
              <img
                src={express}
                alt="logo de express Portfolio Kevin Balmaceda"
              />
              <img
                src={nodejs}
                alt="logo de nodejs Portfolio Kevin Balmaceda"
              />
              <img src={mysql} alt="logo de mysql Portfolio Kevin Balmaceda" />
              <img
                src={mongodb}
                alt="logo de mongodb Portfolio Kevin Balmaceda"
              />
              <img
                src={typescript}
                alt="logo de typescript Portfolio Kevin Balmaceda"
              />
              <img
                src={bootstrap}
                alt="logo de bootstrap Portfolio Kevin Balmaceda"
              />
              <img
                src={materialUi}
                alt="logo de materialUi Portfolio Kevin Balmaceda"
              />
              <img
                src={terminal}
                alt="logo de terminal Portfolio Kevin Balmaceda"
              />
              <img
                src={googlecloud}
                alt="logo de google cloud Portfolio Kevin Balmaceda"
              />
              <img src={git} alt="logo de Git Portfolio Kevin Balmaceda" />
              <img
                src={github}
                alt="logo de GitHub Portfolio Kevin Balmaceda"
              />
              <img
                src={filezilla}
                alt="logo de filezilla Portfolio Kevin Balmaceda"
              />
              <img
                src={debian}
                alt="logo de debian Portfolio Kevin Balmaceda"
              />
              <img
                src={photoshop}
                alt="logo de photoshop Portfolio Kevin Balmaceda"
              />
              {/* <img src={java} alt="logo de Java Portfolio Kevin Balmaceda" /> */}
              {/* fin de los logos */}
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}
