import ProjectItem from "./ProjectItem";
import { Link } from "react-router-dom";
import projectData from "../projectsData";
import ScrollToTop from "../../ScrollToTop";
const Projects = ({ t }) => {
  // const selectedProjects = projectData.slice(0, 6);
  return (
    <section className="projects" id="proyectos">
      <ScrollToTop />
      <h1 className="projects-titulo">{t("PROYECTOS")}</h1>
      <main>
        <div className="projects-content">
          <h1 className="subtitulo-projects">{t("PROJECTS_SUBTITLE")}</h1>
          <div className="list-projects">
            {projectData.map((project, index) => {
              return (
                <ProjectItem
                  key={index}
                  id={project.id}
                  imagen={project.imagen}
                  titulo={t(project.titulo)}
                  tecnologias={project.tecnologias}
                  text={t(project.text)}
                  alt={t(project.titulo)}
                  linkWebside={project.linkWebside}
                  linkRepository={project.linkRepository}
                  t={t}
                />
              );
            })}
          </div>
          {/* <div className="container-btn-project">
            <a href="/projects" className="btn">
              {t("btnVerTodosProyectos")}
            </a>
          </div> */}
        </div>
      </main>
    </section>
  );
};

export default Projects;
